import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import {
  json,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { useEffect } from "react";
import { getToast } from "remix-toast";
import { HoneypotProvider } from "remix-utils/honeypot/react";
import { toast as notify } from "sonner";
import { ExternalScripts } from "./components/ExternalScripts";
import { EpicProgress } from "./components/ui/progress-bar";
import { EpicToaster } from "./components/ui/sonner";
import { getLatestAppVersion } from "./model/app-version/get";
import tailwindStyleSheetUrl from "./styles/tailwind.css?url";
import { getUserId, logout } from "./utils/auth.server";
import { db } from "./utils/db.server";
import { getEnv } from "./utils/env.server";
import { honeypot } from "./utils/honeypot.server";
import { combineHeaders, getDomainUrl } from "./utils/misc";
import { type Theme } from "./utils/theme.server";
import { makeTimings, time } from "./utils/timing.server";

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: tailwindStyleSheetUrl,
  },
];

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings("root loader");
  const appVersion = await getLatestAppVersion();
  const userId = await time(() => getUserId(request), {
    timings,
    type: "getUserId",
    desc: "getUserId in root",
  });
  const user = userId
    ? await time(
        () =>
          db.user.findUniqueOrThrow({
            select: {
              id: true,
              name: true,
              email: true,
              image: {
                select: {
                  id: true,
                },
              },
              // organisations: {
              //   select: {
              //     id: true,
              //     role: true,
              //     organisation_id: true,
              //     organisation: {
              //       select: {
              //         name: true,
              //       },
              //     },
              //   },
              // },
              roles: {
                select: {
                  name: true,
                  permissions: {
                    select: { entity: true, action: true, access: true },
                  },
                },
              },
            },
            where: { id: userId },
          }),
        { timings, type: "find user", desc: "find user in root" }
      )
    : null;
  if (userId && !user) {
    console.info("something weird happened");
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await logout({ request, redirectTo: "/" });
  }
  const { toast, headers: toastHeaders } = await getToast(request);
  const honeyProps = honeypot.getInputProps();
  return json(
    {
      appVersion,
      ENV: getEnv(),
      toast,
      honeyProps,
      user,
      requestInfo: {
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
      },
    },
    {
      headers: combineHeaders(
        { "Server-Timing": timings.toString() },
        toastHeaders
      ),
    }
  );
}

export function Layout({
  children,
  theme = "light",
}: {
  children: React.ReactNode;
  theme?: Theme;
}) {
  return (
    <html lang="en" className={`${theme} h-full overflow-x-hidden`}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <ExternalScripts environment={process.env.NODE_ENV} />
      </head>
      <body className="bg-background text-foreground">
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return (
    <div>
      <h1>Something went wrong</h1>
      <p>{JSON.stringify(error)}</p>
      <Link to="/app">Back to home</Link>
    </div>
  );
};

export default function App() {
  const { toast, honeyProps } = useLoaderData<typeof loader>();

  useEffect(() => {
    if (toast) {
      if (toast?.type === "error") {
        notify.error(toast.message);
      }
      if (toast?.type === "success") {
        notify.success(toast.message);
      }
    }
  }, [toast]);
  return (
    <HoneypotProvider {...honeyProps}>
      <Outlet />
      <EpicProgress />
      <EpicToaster position="bottom-right" richColors />
      <div id="modal-container" />
    </HoneypotProvider>
  );
}

import { useLocation } from "@remix-run/react";
import { useEffect } from "react";

export function ExternalScripts(props: { environment: string }) {
  const location = useLocation();
  const { environment } = props;

  useEffect(() => {
    if (environment !== "development") {
      const appScript = document.createElement("script");

      appScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-KV3K3PJQ');

    `;

      document.head.appendChild(appScript);

      return () => {
        document.head.removeChild(appScript);
      };
    }
  }, [location]);

  return environment === "development" ? null : (
    <>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KV3K3PJQ"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
    </>
  );
}
